<template>
    <div class="customer-manage">
        <div class="add-btn-left">
            <el-button type="primary" @click="addBtn()">添加客户关系管理</el-button>
        </div>
        <el-table :data="customerList" style="width: 100%; flex: 1" height="1%;border: 1px solid #EEE;" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
            <el-table-column prop="name" label="客户关系管理名称"></el-table-column>
            <el-table-column label="操作" align="center"  width="200">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" :underline="false" @click="editCustomer(scope.row.id)">编辑</el-link>
                        <el-link type="danger" :underline="false" @click="deleteCustomer(scope.row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="customerPages.currentPageNum"
                :page-size="customerPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="customerPages.total"
                @current-change="customerCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {customerRelationsList,customerRelationsDel} from '@/utils/apis'
    export default {
        name: "CustomerRelationshipManagement",
        data(){
            return{
                customerList:[
                    {
                        name:'111'
                    }
                ],
                //分页
                customerPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getCustomerList()
        },
        methods:{
            // 获取客户关系管理列表
            getCustomerList() {
                let params = {
                    paging:1,
                    page:this.customerPages.currentPageNum,
                    pageSize:this.customerPages.eachPageNum
                }
                customerRelationsList(params).then((res)=>{
                    this.customerList = res.data.data;
                    this.customerPages.total = res.data.total;
                }).catch((err)=>{
                    console.log('err', err)
                })
            },
            //添加客户关系管理
            addBtn(){
                this.$router.push({
                    path: '/practice/addRelationshipManagement',
                })
            },
            // 编辑客户关系管理
            editCustomer(id){
                this.$router.push({
                    path:'/practice/addRelationshipManagement',
                    query:{
                        id:id
                    }
                })
            },
            //删除客户关系管理
            deleteCustomer(row){
                this.$confirm(`此操作将永久删除，是否继续`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    let params = {
                        id:row.id
                    }
                    customerRelationsDel(params).then((res)=>{
                        this.$message({
                            message:res.msg,
                            type:'success',
                            duration:1000,
                            onClose:()=>{
                                this.getCustomerList()
                            }
                        })
                    }).catch((err)=>{
                        console.log('err', err)
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            // 切换分页
            customerCurrentChange(val){
                this.customerPages.currentPageNum = val;
                this.getCustomerList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .customer-manage{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .add-btn-left{
            display: flex;
            justify-content: flex-end;
            padding-bottom: 20px;
        }
    }

</style>