import { render, staticRenderFns } from "./CustomerRelationshipManagement.vue?vue&type=template&id=64ef4ddc&scoped=true&"
import script from "./CustomerRelationshipManagement.vue?vue&type=script&lang=js&"
export * from "./CustomerRelationshipManagement.vue?vue&type=script&lang=js&"
import style0 from "./CustomerRelationshipManagement.vue?vue&type=style&index=0&id=64ef4ddc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ef4ddc",
  null
  
)

export default component.exports